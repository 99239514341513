/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, setState, useState, useRef} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../_metronic/partials';
import {useNavigate, useParams} from 'react-router-dom';
import PageWrapper from '../../modules/PageWrapper';
import Stepper from './Stepper';
import Tabs from './Tabs';
import Previews from './Previews';
import Form from './templates/googleplay-app/Form';
import FormSite from './templates/googleplay_site/Form';
import NameForm from './NameForm';
import DomainForm from './DomainForm';
import AnalyticsForm from './AnalyticsForm';
import Preloader from '../../modules/Preloader';

import {googleplay_app, googleplay_site} from './templates/objectsTemplates';

async function getWithToken(url, token) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

async function putWithToken(url, token, data) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Перетворюємо об'єкт на JSON-рядок
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending data:', error);
    throw error;
  }
}
async function postWithToken(url, token, data) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Перетворюємо об'єкт на JSON-рядок
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending data:', error);
    throw error;
  }
}
async function deleteWithToken(url, token, data = {}) {
  try {
    const response = await fetch(`https://api.raulnk.com/${url}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Перетворюємо об'єкт на JSON-рядок
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending data:', error);
    throw error;
  }
}

const Component = observer(({setPreloader, isVerify}) => {
  const {pwaId} = useParams();
  const navigate = useNavigate();
  const store = useStore();
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');

  const [prel, setPrel] = useState(false);

  const [token, setToken] = useState('');
  const [id, setId] = useState(null);

  const [step, setStep] = useState(1);
  const [currentLang, setCurrentLang] = useState(null);

  const [data, setData] = useState({});
  const [dataNameForm, setDataNameForm] = useState({offers: []});
  const [dataDomainForm, setDataDomainForm] = useState({activeForm: false});

  const [templatesNames, setTemplatesNames] = useState([]);

  const [domains, setDomains] = useState([]);
  const [subDomains, setSubDomains] = useState([]);
  const [validSubDomain, setValidSubDomain] = useState(true);

  const [formNameError, setFormNameError] = useState([]);
  const [formDomainError, setFormDomainError] = useState([]);

  const [offers, setOffers] = useState([]);
  const [offersOptions, setOffersOptions] = useState([]);
  const [advertisersOptions, setAdvertisersOptions] = useState([]);
  const [countries, setCountries] = useState([]);

  const isUpdate = useRef(false);

  useEffect(() => {
    !!isVerify && getData();
  }, [isVerify]);

  const getUpdateData = async (newOffers, tempList, subDomains, userToken) => {
    setId(pwaId);
    isUpdate.current = true;
    const getPwaData = await store.resourcePWAgetAppConstructor(pwaId);
    const template = tempList.find((item) => item.id === getPwaData?.template_id);

    const newOffersOptions = newOffers
      .filter((item) => item.advertiser_id === getPwaData?.advertiser_id)
      .map((item) => {
        return {value: item.id, label: item.name};
      });

    setOffersOptions(newOffersOptions);
    setDataNameForm({
      name: getPwaData?.name,
      advertiser_id: getPwaData?.advertiser_id,
      offers: getPwaData?.offers,
      offer_id: getPwaData?.offer_id,
      template: {value: template?.id, label: template?.label},
    });

    setDataDomainForm({
      domain_id: getPwaData?.domain_id,
      subdomain_id: getPwaData?.subdomain_id,
      subdomain: subDomains
        .find((item) => item.id === getPwaData?.subdomain_id)
        ?.name?.split('.')[0],
    });

    const newData = JSON.parse(JSON.stringify(getPwaData.data));
    const formArr = Object.entries(newData);

    const newFormArr = formArr.map((item) => {
      const obj = item[1];
      const slides = obj?.slides?.map((slide) => {
        return {
          name: slide,
          image: `${getPwaData?.domain_images}/${pwaId}/images/slides/${item[0]}/${slide}`,
        };
      });
      obj.slides = slides;
      const comments = obj?.comments?.map((comment) => {
        return {
          ...comment,
          avatar: {
            name: comment?.avatar,
            image: `${getPwaData?.domain_images}/${pwaId}/images/users/${item[0]}/${comment?.avatar}`,
          },
        };
      });
      obj.comments = comments;
      obj.appIcon = !!obj.appIcon
        ? {
            name: obj.appIcon,
            image: `${getPwaData?.domain_images}/${pwaId}/images/icons/${item[0]}/${obj.appIcon}`,
          }
        : {name: '', image: ''};

      return [item[0], obj];
    });

    // Створення нового об'єкта
    const newObj = {};
    newFormArr.forEach((item) => {
      newObj[item[0]] = item[1];
    });
    setData(newObj);
  };

  useEffect(() => {
    if (!currentLang) return;
    //Перевіряємо чи існує такий мовний конфіг
    if (!data[currentLang] || Object.keys(data[currentLang]).length === 0) {
      if (dataNameForm?.template?.label === 'Google-Play-Store') {
        setData({...data, [currentLang]: {...googleplay_app, base: false}});
      }

      if (dataNameForm?.template.label === 'Google-Play-Site') {
        setData({...data, [currentLang]: {...googleplay_site, base: false}});
      }
    }
  }, [currentLang, dataNameForm?.template?.label]);

  const getData = async () => {
    setPrel(true);
    setCurrentLang('en');
    setData({...data, en: {...googleplay_app, base: false}});
    const token = store.user.main_token;
    setToken(token);

    const [getTemplatesList, getAdvertisers, getOffers, getCountries, getDomains, getSubDomains] =
      await Promise.all([
        getWithToken('templates/list', token),
        store.getAdvertisersList(),
        store.offersGetOffers(),
        store.getCountries(),
        getWithToken('domains/list', token),
        getWithToken('domains/sub/list', token),
      ]);

    setDomains(
      getDomains.data.map((item) => {
        return {value: item.id, label: item.name};
      })
    );

    setSubDomains(getSubDomains.data);

    setOffers(getOffers);
    setAdvertisersOptions(
      getAdvertisers.map((item) => {
        return {value: item.id, label: item.name};
      })
    );

    setCountries(
      getCountries.map((item) => {
        return {label: item.name, value: item.id};
      })
    );

    setTemplatesNames(
      getTemplatesList.data.map((item) => {
        return {value: item.id, label: item.label};
      })
    );

    if (pwaId !== undefined) {
      getUpdateData(getOffers, getTemplatesList.data, getSubDomains.data, token);
    }

    setPrel(false);
    setPreloader(false);
  };

  const validationForm = (validation, data) => {
    const noValid = [];
    validation.forEach((valid) => {
      if (
        data[valid] === undefined ||
        data[valid] === null ||
        data[valid] === '' ||
        data[valid].length === 0
      ) {
        noValid.push(valid);
      }
    });

    return noValid;
  };

  //робимо watcher для nameForm
  useEffect(() => {
    if (!Object.keys(dataNameForm).length) return;

    if (dataNameForm.advertiser_id !== undefined) {
      const newOffersOptions = offers
        .filter((item) => item.advertiser_id === dataNameForm.advertiser_id)
        .map((item) => {
          return {value: item.id, label: item.name};
        });
      setOffersOptions(newOffersOptions);
    }
  }, [dataNameForm]);

  const saveName = async () => {
    const newData = {
      ...dataNameForm,
      template_id: dataNameForm?.template?.value,
    };
    delete newData.template;
    const valArr = ['name', 'template_id', 'advertiser_id', 'offer_id'];
    newData?.offers?.forEach((item, index) => {
      if (item.country_id === null) {
        valArr.push(`country_${index}`);
      }
      if (item.offer_id === null) {
        valArr.push(`offer_${index}`);
      }
    });
    const validation = validationForm(valArr, newData);

    if (validation.length > 0) {
      return setFormNameError(validation);
    }
    setFormNameError([]);
    setStep(2);
  };

  const saveDomain = async () => {
    const validation = validationForm(['domain_id', 'subdomain'], dataDomainForm);

    if (validation.length > 0) {
      return setFormDomainError(validation);
    }
    setFormDomainError([]);

    if (!validSubDomain) return;

    setStep(3);
  };

  const createPwa = async () => {
    const newDataNameForm = {...dataNameForm, template_id: dataNameForm?.template?.value};

    const createName = await postWithToken('pwa', token, newDataNameForm);
    if (!createName.success) {
      setPrel(false);
      setFormNameError(['name']);
      return toast.error('Название уже сущесвует', {theme: 'colored'});
    }
    setId(createName.data.id);
    return createName.data.id;
  };

  const save = async () => {
    setPrel(true);
    let idResult = null;
    const newDataNameForm = {...dataNameForm, template_id: dataNameForm?.template?.value};

    delete newDataNameForm.advertiser_id;

    // settings;
    if (!!id) {
      const updateName = await putWithToken(`pwa/${id}`, token, newDataNameForm);
      idResult = id;
    } else {
      const createName = await postWithToken('pwa', token, newDataNameForm);
      if (!createName.success) {
        setPrel(false);
        setFormNameError(['name']);
        return toast.error('Название уже сущесвует', {theme: 'colored'});
      }
      setId(createName.data.id);
      idResult = createName.data.id;
    }
    //domain
    if (!isUpdate.current) {
      const postSubDomain = await postWithToken(`domains/${dataDomainForm?.domain_id}/sub`, token, {
        name: dataDomainForm?.subdomain,
      });

      const putDomain = await putWithToken(`pwa/${idResult}/updatePwaDomain`, token, {
        subdomain_id: postSubDomain.data.id,
        domain_id: dataDomainForm?.domain_id,
      });

      isUpdate.current = true;
    }
    //constructor
    const newData = JSON.parse(JSON.stringify(data)); // або structuredClone(data)
    const formArr = Object.entries(newData);

    const newFormArr = formArr.map((item) => {
      const obj = item[1];
      const slides = obj?.slides?.map((slide) => slide.name);
      obj.slides = slides;
      const comments = obj?.comments?.map((comment) => {
        return {...comment, avatar: comment.avatar.name};
      });
      obj.comments = comments;
      obj.appIcon = !!obj.appIcon ? obj.appIcon.name : '';

      return [item[0], obj];
    });

    // Створення нового об'єкта
    const newObj = {};
    newFormArr.forEach((item) => {
      newObj[item[0]] = item[1];
    });

    // Запит на оновлення
    const create = await putWithToken(`pwa/${idResult}/updatePwaData`, token, {data: newObj});
    const build = await postWithToken(`pwa/${idResult}/build`, token);
    setPrel(false);
    navigate('/resources/pwa');
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  return (
    <div
      className={css.container}
      style={{
        '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
        '--text-2': themeMode === 'dark' ? 'rgba(100, 102, 116, 1)' : 'rgba(152, 161, 183, 1)',
        '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
        '--activeColor': themeMode === 'dark' ? 'rgba(27, 132, 255, 1)' : 'rgba(27, 132, 255, 1)',
        '--inputBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(249, 249, 249, 1)',
        '--colorLine': themeMode === 'dark' ? 'rgba(53, 57, 66, 1)' : 'rgba(225, 227, 234, 1)',
        '--buttonGrayBg': themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 241, 244, 1)',
        '--slideSelectorBg':
          themeMode === 'dark' ? 'rgba(27, 28, 33, 1)' : 'rgba(241, 243, 244, 1)',
        '--slideSelectorItemBg':
          themeMode === 'dark' ? 'rgba(42, 46, 54, 1)' : 'rgba(255, 255, 255, 1)',
        '--buttonCommentBg':
          themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(232, 242, 254, 1)',
      }}
    >
      <div className={css.constructorContainer}>
        <Stepper themeMode={themeMode} step={step} />
        <div className={css.table}>
          <Tabs
            themeMode={themeMode}
            setData={setData}
            data={data}
            setCurrentLang={setCurrentLang}
            currentLang={currentLang}
            step={step}
            id={id}
            deleteWithToken={deleteWithToken}
            token={token}
          />
          <section className={css.form} style={{position: 'relative'}}>
            {prel && <Preloader style={{border: 'none'}} />}
            {currentLang === null && (
              <span className={css.warning}>Добавте вкладку для дальнейшего создания PWA...</span>
            )}
            {!!currentLang && (
              <>
                {step === 1 && (
                  <>
                    <NameForm
                      data={dataNameForm}
                      setData={setDataNameForm}
                      templatesNames={templatesNames}
                      postWithToken={postWithToken}
                      deleteWithToken={deleteWithToken}
                      putWithToken={putWithToken}
                      token={token}
                      advertisersOptions={advertisersOptions}
                      offersOptions={offersOptions}
                      countries={countries}
                      setPreloader={setPrel}
                      errors={formNameError}
                      id={id}
                    />
                    <div className={css.next}>
                      <button className={css.blueButton} onClick={saveName}>
                        Продолжить
                      </button>
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <DomainForm
                      data={dataDomainForm}
                      setData={setDataDomainForm}
                      domains={domains}
                      saveDomain={saveDomain}
                      errors={formDomainError}
                      subDomains={subDomains}
                      validSubDomain={validSubDomain}
                      setValidSubDomain={setValidSubDomain}
                      isUpdate={isUpdate.current}
                    />
                    <div className={css.next}>
                      <button
                        className={css.grayButton}
                        onClick={() => setStep(1)}
                        style={{minWidth: '130px'}}
                      >
                        Назад
                      </button>

                      <button
                        className={css.blueButton}
                        style={{minWidth: '130px'}}
                        onClick={saveDomain}
                      >
                        Продолжить
                      </button>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>
                    {dataNameForm?.template?.label === 'Google-Play-Store' && (
                      <>
                        <Form
                          themeMode={themeMode}
                          config={data}
                          setConfig={setData}
                          currentLang={currentLang}
                          setStep={setStep}
                          post={postWithToken}
                          id={id}
                          token={token}
                          createPwa={createPwa}
                          setId={setId}
                        />
                        <div className={css.next}>
                          <button
                            className={css.grayButton}
                            onClick={() => setStep(2)}
                            style={{minWidth: '130px'}}
                          >
                            Назад
                          </button>
                          <button
                            className={css.blueButton}
                            onClick={save}
                            style={{minWidth: '130px'}}
                          >
                            Сохранить
                          </button>
                        </div>
                      </>
                    )}
                    {dataNameForm?.template?.label === 'Google-Play-Site' && (
                      <>
                        <FormSite
                          themeMode={themeMode}
                          config={data}
                          setConfig={setData}
                          currentLang={currentLang}
                          setStep={setStep}
                          post={postWithToken}
                          id={id}
                          token={token}
                          createPwa={createPwa}
                          setId={setId}
                        />
                        <div className={css.next}>
                          <button
                            className={css.grayButton}
                            onClick={() => setStep(2)}
                            style={{minWidth: '130px'}}
                          >
                            Назад
                          </button>
                          <button
                            className={css.blueButton}
                            onClick={save}
                            style={{minWidth: '130px'}}
                          >
                            Сохранить
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </section>
        </div>
      </div>
      {step === 3 && (
        <div className={css.preview}>
          <Previews data={data} currentLang={currentLang} typeApp={dataNameForm?.template?.label} />
        </div>
      )}
    </div>
  );
});

const PwasConstructor = observer(() => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ``},
        {isSeparator: true},
        {title: 'Конструктор PWA', path: `/pwa/constructor`},
      ]}
      title={`Конструктор PWA`}
    >
      <Component />
    </PageWrapper>
  );
});

export default PwasConstructor;
