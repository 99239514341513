/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../../store/store';
import {useLocation, useParams} from 'react-router-dom';
import Header from '../advertising/Header';
import Statistics from '../Board';
import Settings from './Settings';
import GenerationLink from './GenerationLink';
import Source from '../advertising/Source';
import Info from './Info';
import Treker from './Treker/Treker';
import Conversions from '../../../facebook/companies/ConversionsSources';
import PageWrapper from '../../../../../modules/PageWrapper';
import Analyze from './Analyze';

export const MainSource = observer(
  ({page, setPage, isVerify, setPreloader, timeConfig, id, setCompanyID}) => {
    const store = useStore();

    const [data, setData] = useState({});
    const [traffic, setTraffic] = useState({});
    const [treker, setTreker] = useState([]);

    const [firstRendering, setFirstRendering] = useState(false);
    const [link, setLink] = useState({});
    const [channel, setChannel] = useState({});
    const [bot, setBot] = useState({});
    const [bayer, setBayer] = useState({});

    const [conversions, setConversions] = useState({});

    useEffect(() => {
      isVerify && timeConfig.time_config && getData();
    }, [timeConfig, isVerify]);

    const getData = async () => {
      const tc = timeConfig;
      const getMainStats = await store.trafficsFacebookLinksGetMainStats(tc, id);
      const getConversions = await store.trafficsFacebookLinksGetConversions(id, tc);
      const traffic_id = getMainStats?.traffic_id;
      setCompanyID(traffic_id);
      const getInfo = !!traffic_id ? await store.trafficsGetInfo(traffic_id) : {};
      setTraffic(getInfo);

      const getBayer = !!getMainStats.bayer_id
        ? await store.trafficsBayerGetInfo(getMainStats.bayer_id)
        : {};
      setBayer(getBayer);

      const getRomiList = await store.financeCategoryGetRomiList();
      const romi = Math.round(+getMainStats.romi);

      const indicator = getRomiList.find((el) => el.romi_min <= romi && el.romi_max >= romi);

      setConversions(getConversions);
      setData({...getMainStats, indicator});
      setPreloader(false);
    };

    useEffect(() => {
      if (page === 'info' && !firstRendering) {
        setFirstRendering(true);
        getInfoStats();
      }
      // if (page === 'treker') {
      //   getTreker();
      // }
    }, [page, timeConfig]);

    const getInfoStats = async () => {
      const getInfoLink = await store.trafficsInfluenceLinksGetInfo(id);
      const getChannelInfo = !!getInfoLink.channel_id
        ? await store.channelsGetChannel(getInfoLink.channel_id)
        : {};
      const getBot = !!getInfoLink.bot_id ? await store.botsGetBot(getInfoLink.bot_id) : {};

      setLink(getInfoLink);
      setChannel(getChannelInfo);
      setBot(getBot);
    };

    const updateData = (newData) => {
      if(data) {
        setData({...data, name: newData.name});
      }
    }

    return (
      <>
        <Header
          page={page}
          setPage={setPage}
          data={data}
          traffic={traffic}
          links={
            store.roles.includes(6) || store.roles.includes(13) || store.roles.includes(32) || store.roles.includes(38)
              ? [
                  {link: 'statistics', title: 'Общая статистика'},
                  {link: 'info', title: 'Общая информация'},
                  {link: 'treker', title: 'Трекер'},
                  {link: 'analyze', title: 'Окупаемость'},
                ]
              : [
                  {link: 'statistics', title: 'Общая статистика'},
                  {link: 'info', title: 'Общая информация'},
                  {link: 'treker', title: 'Трекер'},
                  {link: 'settings', title: 'Настройки'},
                  {link: 'analyze', title: 'Окупаемость'},
                ]
          }
          actionComponents={[<GenerationLink />]}
        />
        {page === 'statistics' && (
          <>
            <Statistics data={data} />
            <Conversions conversions={conversions} />
          </>
        )}
        {page === 'info' && (
          <Info
            link={link}
            channel={channel}
            traffic={traffic}
            bot={bot}
            data={data}
            bayer={bayer}
          />
        )}

        {page === 'treker' && <Treker timeConfig={timeConfig} id={id} />}
        {page === 'settings' && <Settings data={data} bayer={bayer} updateData={updateData} />}
        {page === 'analyze' && <Analyze id={id} tc={timeConfig} />}
      </>
    );
  }
);

const SourceWrapper = () => {
  const [page, setPage] = useState('statistics');
  const [companyID, setCompanyID] = useState();

  const location = useLocation();
  const {id} = useParams();

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {
          title: `Кампания #${companyID || ''}`,
          path: `/traffics/facebook/companies/${companyID}`,
          isActive: true,
        },
        {isSeparator: true},
        {
          title: `Сорс`,
          path: `/traffics/facebook/source/${id}`,
          isActive: true,
        },
      ]}
      title={`Сорс #${id}`}
    >
      <MainSource page={page} setPage={setPage} id={id} setCompanyID={setCompanyID} />
    </PageWrapper>
  );
};

export default SourceWrapper;
