/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../store/store';
import {observer} from 'mobx-react';
import {CreateAppModal} from '../../../../_metronic/partials';
import {toast} from 'react-toastify';
import {Button} from '../../../shared/Buttons';
import Warning from '../../../modules/Warning';
import {SelectorWithSearch} from '../../../shared/Selectors';

const AddPixel = ({setWarning, warning, setSave, save, show, getPixels}) => {
  const store = useStore();
  const [data, setData] = useState({pixel_id: '', pixel_token: '', name: ''});
  const [events, setEvents] = useState({
    subscribe_event_id: {value: '', label: 'Не выбрано'},
    appeal_event_id: {value: '', label: 'Не выбрано'},
    bot_event_id: {value: '', label: 'Не выбрано'},
    registration_event_id: {value: '', label: 'Не выбрано'},
    fd_event_id: {value: '', label: 'Не выбрано'},
    rd_event_id: {value: '', label: 'Не выбрано'},
    deposit_event_id: {value: '', label: 'Не выбрано'},
    cpa_event_id: {value: '', label: 'Не выбрано'},
    config: {value: '', label: 'Не выбрано'},
  });

  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getParams();
  }, []);

  const getParams = async () => {
    const getFacebookEvents = await store.pixelsGetFacebookEvents();
    setOptions(
      getFacebookEvents.map((item) => {
        return {value: item?.id, label: item?.name};
      })
    );
  };

  const addEvent = (e, name) => {
    clearError(name);
    const newEvents = {...events};
    const newEventsArr = Object.entries({...events});
    const findIndex = newEventsArr.findIndex((item) => item[1].value === e.value);
    if (findIndex === -1) {
      newEvents[name] = e;
      return setEvents(newEvents);
    }

    newEvents[newEventsArr[findIndex][0]] = {label: 'Не выбрано', value: ''};
    newEvents[name] = e;
    setEvents(newEvents);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      getPixels();
      toast.success(`${message}`, {});
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;

    clearError(name);

    const newData = {...data};
    newData[name] = value;
    setData(newData);
  };

  const clearError = (field) => {
    const newErrors = [...errors];
    const index = errors.indexOf(field);
    if (index !== -1) {
      newErrors.splice(index, 1);
      return setErrors(newErrors);
    }
  };

  const savePixel = async () => {
    const newEventsArr = Object.entries({...events});
    const newEvents = {};

    newEventsArr.forEach((item) => {
      newEvents[item[0]] = item[1].value;
    });

    const newData = {...data, ...newEvents};
    const entries = Object.entries(newData);
    const newErrors = [...errors];

    entries.forEach((el, i) => {
      if (
        el.includes('pixel_id') ||
        el.includes('name') ||
        el.includes('pixel_token') ||
        el.includes('subscribe_event_id') ||
        el.includes('appeal_event_id') ||
        el.includes('bot_event_id') ||
        el.includes('registration_event_id') ||
        el.includes('fd_event_id') ||
        el.includes('rd_event_id') ||
        el.includes('deposit_event_id') ||
        el.includes('cpa_event_id') ||
        el.includes('config')
      ) {
        if (!String(el[1]) && !newErrors.includes(el[0])) {
          newErrors.push(el[0]);
        }
      }
    });

    if (newErrors.length) return setErrors(newErrors);

    const result = await store.addPixel(newData);
    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');

    setSave(true);
    show(false);
  };

  return (
    <form className='form fv-plugins-bootstrap5 fv-plugins-framework'>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Название</label>

        <input
          type='text'
          className='form-control form-control-solid mb-3 mb-lg-0'
          placeholder=''
          name='name'
          value={data?.name || ''}
          onChange={handleChange}
        />

        {errors.includes('name') && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>

      <div className='row'>
        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Pixel ID</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            name='pixel_id'
            value={data.pixel_id || ''}
            onChange={handleChange}
          />

          {errors.includes('pixel_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>

        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Маркер доступа</label>

          <input
            type='text'
            className='form-control form-control-solid mb-3 mb-lg-0'
            placeholder=''
            name='pixel_token'
            value={data?.pixel_token || ''}
            onChange={handleChange}
          />

          {errors.includes('pixel_token') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
      </div>
      <div className='fv-row mb-7 fv-plugins-icon-container'>
        <label className='required fw-semibold fs-6 mb-2'>Config</label>

        <SelectorWithSearch
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: '42px',
            }),
          }}
          options={[{label: 'Telegram', value: 'telegram'}, {label: 'App', value: 'app'}]}
          value={events.config}
          actions={(e) => addEvent(e, 'config')}
        />

        {errors.includes('config') && (
          <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
        )}
      </div>
      <div className='row'>
        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие подписки</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events.subscribe_event_id}
            actions={(e) => addEvent(e, 'subscribe_event_id')}
          />

          {errors.includes('subscribe_event_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>

        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие обращения</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events?.appeal_event_id}
            actions={(e) => addEvent(e, 'appeal_event_id')}
          />

          {errors.includes('appeal_event_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие бота</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events?.bot_event_id}
            actions={(e) => addEvent(e, 'bot_event_id')}
          />

          {errors.includes('bot_event_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>

        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие регистрации</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events?.registration_event_id}
            actions={(e) => addEvent(e, 'registration_event_id')}
          />

          {errors.includes('registration_event_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие FD</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events?.fd_event_id}
            actions={(e) => addEvent(e, 'fd_event_id')}
          />

          {errors.includes('fd_event_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>

        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие RD</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events?.rd_event_id}
            actions={(e) => addEvent(e, 'rd_event_id')}
          />

          {errors.includes('rd_event_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие депозита</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events?.deposit_event_id}
            actions={(e) => addEvent(e, 'deposit_event_id')}
          />
          {errors.includes('deposit_event_id') && (
            <div className='fs-6 fw-semibold form-label mb-2 text-danger'>Обязательное поле</div>
          )}
        </div>

        <div className='col-md-6 fv-row fv-plugins-icon-container mb-10'>
          <label className='required fw-semibold fs-6 mb-2'>Событие CPA</label>

          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
              }),
            }}
            options={options}
            value={events?.cpa_event_id}
            actions={(e) => addEvent(e, 'cpa_event_id')}
          />

          {errors.includes('cpa_event_id') && (
            <div className='required fs-6 fw-semibold form-label mb-2 text-danger'>
              Обязательное поле
            </div>
          )}
        </div>
      </div>

      <div className='d-flex flex-end pt-10'>
        <Button
          action={() => {
            savePixel();
          }}
        >
          Сохранить
        </Button>
      </div>

      <CreateAppModal
        customWidth='mw-400px'
        padding='pb-lg-10 pt-lg-10 px-lg-10'
        show={warning}
        handleClose={() => setWarning(false)}
        showHeader={false}
        isMessage={true}
      >
        <Warning
          buttons={[
            {
              action: () => {
                setWarning(false);
              },
              title: <i className='bi bi-x p-0 fs-1'></i>,
              class: 'btn-light',
            },
            {
              action: () => {
                setWarning(false);
                show(false);
              },
              title: <i className='bi bi-check p-0 fs-1'></i>,
              class: 'btn-light-primary',
            },
          ]}
        />
      </CreateAppModal>
    </form>
  );
};

export default observer(AddPixel);
