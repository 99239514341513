import React, {useState} from 'react';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {Button} from '../../../../../shared/Buttons';
import iconPlug from '../../../../../../_metronic/assets/media/user.png';

const GenerationLink = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  return (
    <>
      <Button
        className='me-3'
        // color='btn'
        action={() => setShowCreateAppModal(true)}
      >
        Генерация ссылки
      </Button>
      <CreateAppModal
        customWidth='mw-650px'
        padding='scroll-y mx-5 mx-xl-18 pt-0 pb-15'
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        showHeader={false}
      >
      </CreateAppModal>
    </>
  );
};

export default GenerationLink;
