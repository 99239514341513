import React from 'react';
import {formatNumber, formatNumber2} from '../../../functions/formatNumber';
import moment from 'moment';

const Dialogs = ({data}) => {
  const convertSeconds = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600); // Кількість годин
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Кількість хвилин
    const seconds = totalSeconds % 60; // Залишок секунд, якщо потрібен

    return `${hours ? hours : 0}ч ${minutes ? minutes : 0}мин`;
  };

  return (
    <div className="parametersDialogs">
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <i class='ki-duotone ki-time fs-1 text-primary'>
            <span class='path1'></span>
            <span class='path2'></span>
          </i>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
            {formatNumber(data?.count_night_leads)}
          </span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>Ночной Трафик</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <i class='ki-duotone ki-timer fs-1 text-primary'>
            <span class='path1'></span>
            <span class='path2'></span>
            <span class='path3'></span>
          </i>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
            {convertSeconds(data?.avg_leads_cpa_time_second)}
          </span>
          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>
            Время закрытия (avg)
          </span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <i class='ki-duotone ki-message-question fs-1 text-primary'>
            <span class='path1'></span>
            <span class='path2'></span>
            <span class='path3'></span>
          </i>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
            {formatNumber2(data?.conversion_leads?.msg2reg)}%
          </span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>msg2reg</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <i class='ki-duotone ki-right-square fs-1 text-primary'>
            <span class='path1'></span>
            <span class='path2'></span>
          </i>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
            {formatNumber2(data?.conversion_leads?.reg2cpa)}%
          </span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>reg2cpa</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <i class='ki-duotone ki-message-minus fs-1 text-primary'>
            <span class='path1'></span>
            <span class='path2'></span>
          </i>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
            {formatNumber2(data?.new_appeals_ignore_stats)}%
          </span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>Игнор менеджеров</span>
        </div>
      </div>
      <div
        className='card px-xl-3 py-xl-3 px-xxl-6 py-xxl-5 px-6 py-5 text-nowrap'
        
      >
        <div className='symbol symbol-30px me-5 mb-8'>
          <i class='ki-duotone ki-directbox-default fs-1 text-primary'>
            <span class='path1'></span>
            <span class='path2'></span>
            <span class='path3'></span>
            <span class='path4'></span>
          </i>
        </div>
        <div className='m-0'>
          <span className='text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1'>
            {formatNumber2(data?.ignore_messages_percentage)}%
          </span>

          <span className='text-gray-500 fw-semibold fs-6 fs-xxl-6 fs-xl-7'>
            Игнор (3 сообщения)
          </span>
        </div>
      </div>
    </div>
  );
};

export default Dialogs;
