/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import Navigation from '../../../../../modules/NavigationBloggers';
import Card from '../../../../../shared/Card';
import { formatNumber2 } from "../../../../../functions/formatNumber";

const Header = ({
  setPage,
  page,
  links = [{link: 'employees', title: 'Сотрудники'}],
  data,
  traffic,
  actionComponents,
}) => {
  const store = useStore();
  return (
    <>
      <Card className='card mb-8'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
            <div className='flex-grow-1'>
              <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                      {data?.name}
                    </span>
                    {!store.roles.includes(19) &&
                      <span className={`badge badge-light-${data?.indicator?.indicator} me-auto`}>
                      {formatNumber2(data?.romi)}%
                    </span>}
                  </div>

                  <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                    <span className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2'>
                      #{data?.id}
                    </span>

                    {/* <Link to={`/sales/ltc/teams/${data?.sales_team_id}`}> */}
                    <span className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 cursor-pointer'>
                      <i className='ki-duotone ki-profile-circle fs-4 me-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                      {traffic?.name}
                    </span>
                    {/* </Link> */}
                  </div>
                </div>

                <div className='d-flex mb-4'>
                  {actionComponents.map((el, i) => {
                    return <div key={i}>{el}</div>;
                  })}
                </div>
              </div>

              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bold'>
                        {moment(new Date(data?.created_at * 1000)).format('ll')}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Создан</div>
                  </div>
                  <Stats title={'Расходы'} count={`${data?.cost}$`} arrow={true} />
                  {!store.roles.includes(19) && (
                    <Stats title={'Доход'} count={`${data?.revenue}$`} arrow={false} />
                  )}
                  {!store.roles.includes(19) && (
                    <Stats title={'Прибыль'} count={`${data?.profit}$`} arrow={true} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='separator'></div>
          <Navigation page={page} setPage={setPage} links={links} />
        </div>
      </Card>
    </>
  );
};

const Stats = ({title, count, arrow}) => {
  return (
    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
      <div className='d-flex align-items-center'>
        {!!arrow && (
          <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        {!arrow && (
          <i className='ki-duotone ki-arrow-down fs-3 text-danger me-2'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        <div className='fs-4 fw-bold'>{count}</div>
      </div>

      <div className='fw-semibold fs-6 text-gray-400'>{title}</div>
    </div>
  );
};

export default observer(Header);
