/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import {Link, useParams} from 'react-router-dom';
import PageWrapper from '../../../../modules/PageWrapper';
import Card from '../../../../shared/Card';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import moment from 'moment';
import {Button} from '../../../../shared/Buttons';
import {formatNumberToCash, formatNumber} from '../../../../functions/formatNumber';
import Navigation from '../../../../modules/NavigationBloggers';
import Advertisements from './Advertisements';
import Pwa from './Pwa';
import Scheme from './Scheme';

const Bayer = observer(({timeConfig, isVerify, setPreloader, id, page, setPage, links}) => {
  const store = useStore();

  const [time, setTime] = useState({});

  const [data, setData] = useState({});
  const [traffic, setTraffic] = useState([]);
  const [pwa, setPwa] = useState([]);
  const [scheme, setScheme] = useState([]);

  const [advertisersOptions, setAdvertisersOptions] = useState([]);
  const [offersOptions, setOffersOptions] = useState([]);

  const [trafficFilters, setTrafficFilters] = useState({});
  const [pwaFilters, setPwaFilters] = useState({});
  const [schemeFilters, setSchemeFilters] = useState({});

  useEffect(() => {
    isVerify && timeConfig.time_config && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const [getTrafficStats, getAdsInfo, pwaData, schemeData, getAdvertisersList, getOffersList] =
      await Promise.all([
        store.trafficsFacebookBayerMainStats(id, timeConfig),
        store.trafficsFacebookBayerGetTrafficList({timeConfig, id, ...trafficFilters}),
        store.trafficsFacebookBayerGetAppSchedule({timeConfig, id, ...pwaFilters}),
        store.trafficsFacebookBayerGetScheme({timeConfig, id, ...schemeFilters}),
        store.getAdvertisersList(),
        store.offersGetOffersList(),
      ]);

    setData(getTrafficStats.data);
    setTime(getTrafficStats.time);
    setTraffic(getAdsInfo);
    setPwa(pwaData);
    setScheme(schemeData);
    setPreloader(false);

    setAdvertisersOptions(
      getAdvertisersList.map((item) => {
        return {value: item.id, label: item.name};
      })
    );

    setOffersOptions(
      getOffersList.map((item) => {
        return {value: item.id, label: item.name};
      })
    );
  };

  const searchTraffic = async (param) => {
    setTrafficFilters({...trafficFilters, ...param});
    const getTraffic = await store.trafficsFacebookBayerGetTrafficList({
      ...trafficFilters,
      ...param,
      timeConfig,
      id,
    });
    setTraffic(getTraffic);
  };

  const searchPwa = async (param) => {
    setPwaFilters({...pwaFilters, ...param});
    const getPwa = await store.trafficsFacebookBayerGetAppSchedule({
      ...pwaFilters,
      ...param,
      timeConfig,
      id,
    });
    setPwa(getPwa);
  };

  const searchScheme = async (param) => {
    setSchemeFilters({...schemeFilters, ...param});
    const getScheme = await store.trafficsFacebookBayerGetScheme({
      ...schemeFilters,
      ...param,
      timeConfig,
      id,
    });
    setScheme(getScheme);
  };

  return (
    <>
      <Card className='card mb-8'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={
                    data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : imagePlug
                  }
                  alt=''
                />
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-md-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                      {`${data?.first_name} ${data?.last_name}`}
                    </span>
                    <span className={`badge badge-light-${data?.indicator?.indicator} me-auto`}>
                      {data?.statistics?.romi}%
                    </span>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400'>
                    #{data?.id}
                  </div>
                </div>
                <div className='d-flex mb-4'>
                  <Link to={`/users/list/user/${data?.id}/info`}>
                    <Button>Карточка сотрудника</Button>
                  </Link>
                </div>
              </div>

              <div className='d-flex flex-wrap justify-content-start'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bold'>
                        {moment(new Date(data?.created_at * 1000)).format('ll')}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Создан</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i> */}
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumberToCash(data?.statistics?.profit)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Доход</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i> */}
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumberToCash(data?.statistics?.cost)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Расходы</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i> */}
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumber(data?.statistics?.count_registrations)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Регистрации</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      {/* <i className='ki-duotone ki-arrow-up fs-3 text-success me-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i> */}
                      <div
                        className='fs-4 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value='75'
                      >
                        {formatNumber(data?.statistics?.count_qualifications)}
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Квалификации</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Navigation page={page} setPage={setPage} links={links} />
        </div>
      </Card>
      <>
        {page === 'statistics' && (
          <Statistics id={id} isVerify={isVerify} timeConfig={timeConfig} />
        )}
        {page === 'advertisements' && (
          <Advertisements
            title={'Рекламные кампании'}
            subTitle={'Рекламные кампании не были еще созданы...'}
            data={traffic}
            advertisersOptions={advertisersOptions}
            offersOptions={offersOptions}
            searchTraffic={searchTraffic}
            trafficFilters={trafficFilters}
            time={time}
            timeConfig={timeConfig}
          />
        )}
        {page === 'pwa' && (
          <Pwa
            title={'PWA'}
            subTitle={'Трафика нет...'}
            data={pwa}
            searchPwa={searchPwa}
            pwaFilters={pwaFilters}
            advertisersOptions={advertisersOptions}
            offersOptions={offersOptions}
            time={time}
          />
        )}
        {page === 'scheme' && (
          <Scheme
            title={'Схемы'}
            subTitle={'Трафика нет...'}
            data={scheme}
            searchScheme={searchScheme}
            schemeFilters={schemeFilters}
            advertisersOptions={advertisersOptions}
            offersOptions={offersOptions}
            time={time}
          />
        )}
      </>
    </>
  );
});

const Statistics = observer(({isVerify, timeConfig, id}) => {
  const store = useStore();
  const [data, setData] = useState({});

  useEffect(() => {
    isVerify && !!timeConfig.time_config && getData();
  }, [isVerify && timeConfig]);

  const getData = async () => {
    const getInfo = await store.trafficsFacebookBayerInfo(timeConfig, id);
    setData(getInfo);
  };

  return (
    <div className='row g-5 g-xl-10'>
      <div className='col-xl-6 mb-5 mb-xl-10'>
        <div className='card'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-800'>PWA</span>
            </h3>
          </div>
          <div className='card-body p-9'>
            <div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Квалификации</label>
                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {data?.pwa_count_qualifications}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Доход</label>
                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {formatNumberToCash(data?.pwa_revenue)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-6 mb-5 mb-xl-10'>
        <div className='card'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-800'>Схемы</span>
            </h3>
          </div>
          <div className='card-body p-9'>
            <div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Квалификации</label>
                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {data?.telegram_count_qualifications}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-4 fw-semibold text-muted'>Доход</label>
                <div className='col-8 d-flex align-items-center'>
                  <span className='fw-bold fs-6 text-gray-800 me-2'>
                    {formatNumberToCash(data?.telegram_revenue)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const BayerWrapper = () => {
  const {id} = useParams();
  const [page, setPage] = useState('statistics');

  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: `/dashboard/main`},
        {isSeparator: true},
        {title: 'Facebook', path: `/traffics/facebook/bayers`},
        {isSeparator: true},
        {title: `Байер #${id}`, path: `/traffics/facebook/bayers/${id}`, isActive: true},
      ]}
      title={`Байер Facebook #${id}`}
      firstTimeConfig='current_month'
    >
      <Bayer
        id={id}
        links={[
          {link: 'statistics', title: 'Общая статистика'},
          {link: 'advertisements', title: 'Кампании'},
          {link: 'pwa', title: 'PWA'},
          {link: 'scheme', title: 'Схемы'},
        ]}
        page={page}
        setPage={setPage}
      />
    </PageWrapper>
  );
};

export default BayerWrapper;
