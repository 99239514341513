/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import {useLocation, useParams} from 'react-router-dom';
import BloggerHeader from './BloggersHeader/BloggerHeader';
import {Button} from '../../../../shared/Buttons';
import AddTarget from './BloggersHeader/AddTarget';
import Statistics from './Board';
import Advertisements from './Advertisements';
import Settings from './Settings';
import Analyze from './Analyze';
import PageWrapper from '../../../../modules/PageWrapper';
import Board2 from './Board2';

export const Blogger = observer(({page, setPage, timeConfig, isVerify, setPreloader, id}) => {
  const store = useStore();

  const [data, setData] = useState({});
  const [links, setLinks] = useState([]);
  const [statuses, setStatuses] = useState({});
  const [romiList, setRomiList] = useState({});

  useEffect(() => {
    isVerify && getData();
  }, [timeConfig, isVerify]);

  const getData = async () => {
    const tc = timeConfig;
    const [getTrafficStats, getRomiList, getLinks, getStatuses] = await Promise.all([
      store.trafficsInfluenceGetTrafficStats(id, tc),
      store.financeCategoryGetRomiList(),
      store.trafficsInfluenceGetLinks(id),
      store.trafficsInfluenceLinksGetStatuses(),
    ]);

    const romi = Math.round(+getTrafficStats.romi);
    const indicator = getRomiList.find((el) => el.romi_min <= romi && el.romi_max >= romi);
    const newLinks = getLinks.map((el) => {
      const romi = Math.round(+el.romi);
      const indicator = getRomiList.find((item) => item.romi_min <= romi && item.romi_max >= romi);
      const status = getStatuses.find((item) => item.status === el.status);

      return {...el, indicator, status: status ? status : null};
    });
    setData({...getTrafficStats, indicator});
    setPreloader(false);
    setStatuses(getStatuses);
    setRomiList(getRomiList);
    setLinks(newLinks);
  };

  const updateTrafficList = (newLink) => {
    const romi = Math.round(+newLink.romi);
    const indicator = romiList.find((item) => item.romi_min <= romi && item.romi_max >= romi);
    const status = statuses.find((item) => item.status === newLink.status);
    const count_leads = newLink?.count_leads ? newLink.count_leads : 0;
    setLinks([{...newLink, indicator, status: status ? status : null, count_leads}, ...links]);
  };

  const updateData = (newData) => {
    if (data) {
      setData({
        ...data,
        name: newData.name,
        source_link: newData.source_link,
        avatar: newData.avatar,
      });
    }
  };

  return (
    <>
      <BloggerHeader
        page={page}
        setPage={setPage}
        data={data}
        links={
          store.roles.includes(19) ||
          store.roles.includes(13) ||
          store.roles.includes(32) ||
          store.roles.includes(38)
            ? [
                {link: 'statistics', title: 'Общая статистика'},
                {link: 'advertisements', title: 'Рекламные кампании'},
                {link: 'analyze', title: 'Окупаемость'},
              ]
            : [
                {link: 'statistics', title: 'Общая статистика'},
                {link: 'advertisements', title: 'Рекламные кампании'},
                {link: 'settings', title: 'Настройки'},
                {link: 'analyze', title: 'Окупаемость'},
              ]
        }
        actionComponents={[
          !!data?.source_link ? (
            <Button
              className='me-3'
              color='btn-light btn-active-color-primary'
              action={() => window.open(data?.source_link, '_blank')}
            >
              Источник
            </Button>
          ) : (
            <></>
          ),
          store.roles.includes(19) ||
          store.roles.includes(13) ||
          store.roles.includes(32) ||
          store.roles.includes(38) ? (
            <></>
          ) : (
            <AddTarget
              traffic_id={data.id}
              updateTrafficList={updateTrafficList}
              setPage={setPage}
            />
          ),
        ]}
      />
      {page === 'statistics' && (
        <>
          <Statistics data={data} isBlogger={true} />
          <Board2 data={data}/>
        </>
      )}
      {page === 'advertisements' && (
        <Advertisements
          links={links}
          title={'Рекламные кампании'}
          subTitle={'Рекламные кампании не были еще созданы...'}
          type={'advertising'}
        />
      )}
      {page === 'settings' && <Settings data={data} updateData={updateData} />}
      {page === 'analyze' && <Analyze id={id} tc={timeConfig} />}
    </>
  );
});

const BloggerWrapper = ({timeConfig, timeStart, timeEnd}) => {
  const [page, setPage] = useState('statistics');
  const [breadcrumbs, setBreadcrumbs] = useState({br: [], title: ''});

  const location = useLocation();
  const {id} = useParams();

  useEffect(() => {
    switch (page) {
      case 'statistics': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {title: 'Трафик от блогеров', path: `/traffics/influence/bloggers`},
            {isSeparator: true},
            {
              title: 'Общая статистика',
              path: `/traffics/influence/bloggers/${id}`,
              isActive: true,
            },
          ],
          title: `Блогер #${id}`,
        });
        break;
      }
      case 'advertisements': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {title: 'Трафик от блогеров', path: `/traffics/influence/bloggers`},
            {isSeparator: true},
            {title: 'Рекламы', path: `/traffics/influence/bloggers/${id}`, isActive: true},
          ],
          title: `Блогер #${id}`,
        });
        break;
      }
      case 'settings': {
        setBreadcrumbs({
          br: [
            {title: 'PanelHunt', path: `/dashboard/main`},
            {isSeparator: true},
            {title: 'Трафик от блогеров', path: `/traffics/influence/bloggers`},
            {isSeparator: true},
            {title: 'Настройки', path: `/traffics/influence/bloggers/${id}`, isActive: true},
          ],
          title: `Блогер #${id}`,
        });
        break;
      }
    }
  }, [page]);

  return (
    <PageWrapper
      breadcrumbs={breadcrumbs.br}
      title={breadcrumbs.title}
      firstTimeConfig='current_month'
    >
      <Blogger
        page={page}
        setPage={setPage}
        timeConfig={timeConfig}
        timeStart={timeStart}
        timeEnd={timeEnd}
        id={id}
      />
    </PageWrapper>
  );
};

export default BloggerWrapper;
