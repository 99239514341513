/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, setState, useState} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import {CreateAppModal} from '../../../_metronic/partials';
import localeCodes from 'locale-codes';
import InputSearch from '../../shared/InputSearch';

const Tabs = ({
  themeMode,
  setData,
  data,
  setCurrentLang,
  currentLang,
  step,
  id,
  deleteWithToken,
  token,
}) => {
  const [languages, setLanguages] = useState([]);
  const [searchLanguages, setSearchLanguages] = useState([]);

  return (
    <section className={css.tabs}>
      {step === 3 && (
        <>
          <SelectLanguage
            themeMode={themeMode}
            data={data}
            setData={setData}
            languages={languages}
            searchLanguages={searchLanguages}
            setSearchLanguages={setSearchLanguages}
            setCurrentLang={setCurrentLang}
            setLanguages={setLanguages}
          />
          {Object.keys(data).map((item) => {
            const title = languages.find((lang) => lang.tag === item);
            return (
              <div
                className={classNames(css.tab, currentLang === item && css._active)}
                key={item}
                onClick={() => setCurrentLang(item)}
              >
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.4'
                    d='M5.73742 15.6825C5.71492 15.6825 5.68492 15.6975 5.66242 15.6975C4.20742 14.9775 3.02242 13.785 2.29492 12.33C2.29492 12.3075 2.30992 12.2775 2.30992 12.255C3.22492 12.525 4.16992 12.7275 5.10742 12.885C5.27242 13.83 5.46742 14.7675 5.73742 15.6825Z'
                    fill='#1B84FF'
                  />
                  <path
                    opacity='0.4'
                    d='M15.705 12.3375C14.9625 13.83 13.725 15.0375 12.2175 15.765C12.5025 14.8125 12.7425 13.8525 12.9 12.885C13.845 12.7275 14.775 12.525 15.69 12.255C15.6825 12.285 15.705 12.315 15.705 12.3375Z'
                    fill='#1B84FF'
                  />
                  <path
                    opacity='0.4'
                    d='M15.765 5.78249C14.82 5.49749 13.8675 5.26499 12.9 5.09999C12.7425 4.13249 12.51 3.17249 12.2175 2.23499C13.77 2.97749 15.0225 4.22999 15.765 5.78249Z'
                    fill='#1B84FF'
                  />
                  <path
                    opacity='0.4'
                    d='M5.73736 2.31754C5.46736 3.23254 5.27236 4.16254 5.11486 5.10754C4.14736 5.25754 3.18736 5.49754 2.23486 5.78254C2.96236 4.27504 4.16986 3.03754 5.66236 2.29504C5.68486 2.29504 5.71486 2.31754 5.73736 2.31754Z'
                    fill='#1B84FF'
                  />
                  <path
                    d='M11.6176 4.9425C9.87757 4.7475 8.12257 4.7475 6.38257 4.9425C6.57007 3.915 6.81007 2.8875 7.14757 1.8975C7.16257 1.8375 7.15507 1.7925 7.16257 1.7325C7.75507 1.59 8.36257 1.5 9.00007 1.5C9.63007 1.5 10.2451 1.59 10.8301 1.7325C10.8376 1.7925 10.8376 1.8375 10.8526 1.8975C11.1901 2.895 11.4301 3.915 11.6176 4.9425Z'
                    fill='#1B84FF'
                  />
                  <path
                    d='M4.9425 11.6176C3.9075 11.4301 2.8875 11.1901 1.8975 10.8526C1.8375 10.8376 1.7925 10.8451 1.7325 10.8376C1.59 10.2451 1.5 9.63757 1.5 9.00007C1.5 8.37007 1.59 7.75507 1.7325 7.17007C1.7925 7.16257 1.8375 7.16257 1.8975 7.14757C2.895 6.81757 3.9075 6.57007 4.9425 6.38257C4.755 8.12257 4.755 9.87757 4.9425 11.6176Z'
                    fill='#1B84FF'
                  />
                  <path
                    d='M16.4999 9.00007C16.4999 9.63757 16.4099 10.2451 16.2674 10.8376C16.2074 10.8451 16.1624 10.8376 16.1024 10.8526C15.1049 11.1826 14.0849 11.4301 13.0574 11.6176C13.2524 9.87757 13.2524 8.12257 13.0574 6.38257C14.0849 6.57007 15.1124 6.81007 16.1024 7.14757C16.1624 7.16257 16.2074 7.17007 16.2674 7.17007C16.4099 7.76257 16.4999 8.37007 16.4999 9.00007Z'
                    fill='#1B84FF'
                  />
                  <path
                    d='M11.6176 13.0575C11.4301 14.0925 11.1901 15.1125 10.8526 16.1025C10.8376 16.1625 10.8376 16.2075 10.8301 16.2675C10.2451 16.41 9.63007 16.5 9.00007 16.5C8.36257 16.5 7.75507 16.41 7.16257 16.2675C7.15507 16.2075 7.16257 16.1625 7.14757 16.1025C6.81757 15.105 6.57007 14.0925 6.38257 13.0575C7.25257 13.155 8.12257 13.2225 9.00007 13.2225C9.87757 13.2225 10.7551 13.155 11.6176 13.0575Z'
                    fill='#1B84FF'
                  />
                  <path
                    d='M11.8225 11.8225C9.94668 12.0592 8.05332 12.0592 6.1775 11.8225C5.94083 9.94668 5.94083 8.05332 6.1775 6.1775C8.05332 5.94083 9.94668 5.94083 11.8225 6.1775C12.0592 8.05332 12.0592 9.94668 11.8225 11.8225Z'
                    fill='#1B84FF'
                  />
                </svg>
                <span>{title?.name}</span>
                {item !== 'en' && (
                  <span
                    className={css.del}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCurrentLang('en');
                      const newData = {...data};
                      delete newData[item];
                      setData(newData);
                      await deleteWithToken('uploads', token, {id: +id, lang: item, name: 'del'});
                    }}
                  >
                    <i className='ki-duotone ki-plus fs-2'></i>
                  </span>
                )}
              </div>
            );
          })}
        </>
      )}
    </section>
  );
};

const SelectLanguage = ({
  themeMode,
  data,
  setData,
  languages,
  searchLanguages,
  setSearchLanguages,
  setCurrentLang,
  setLanguages,
}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);

  useEffect(() => {
    const sort = localeCodes.all.filter((item) => item.location === null);
    setLanguages(sort);
    setSearchLanguages(sort);
  }, [showCreateAppModal]);

  return (
    <>
      <div className={classNames(css.tab, css._add)} onClick={() => setShowCreateAppModal(true)}>
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.125 9H16.875'
            stroke='#1B84FF'
            strokeWidth='1.35'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M9 16.875L9 1.125'
            stroke='#1B84FF'
            strokeWidth='1.35'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <CreateAppModal
        customWidth='mw-450px'
        padding='py-lg-0 px-lg-0'
        show={showCreateAppModal}
        handleClose={() => {
          setSearchLanguages([...languages]);
          setShowCreateAppModal(false);
        }}
        title={'Выбор языка'}
      >
        <section
          className={css.selectLanguage}
          style={{
            '--text-1': themeMode === 'dark' ? 'rgba(245, 245, 245, 1)' : 'rgba(31, 33, 41, 1)',
            '--hoverBg': themeMode === 'dark' ? 'rgba(24, 35, 49, 1)' : 'rgba(230, 240, 253, 1)',
            '--bg': themeMode === 'dark' ? 'rgba(21, 23, 28, 1)' : 'rgba(255, 255, 255, 1)',
          }}
        >
          <div className={css.search}>
            <InputSearch
              style={{backgroundColor: 'transparent', border: 'none', outline: 'none'}}
              title={'Поиск...'}
              array={languages}
              newArray={setSearchLanguages}
              searchParameters={['name', 'local']}
            />
          </div>
          {searchLanguages.map((item, index) => {
            return (
              <React.Fragment key={`${item.name}-${item.location}-${index}`}>
                {!Object.keys(data).includes(item.tag) && (
                  <div
                    className={css.language}
                    onClick={() => {
                      setCurrentLang(item.tag);
                      setData({...data, [item.tag]: {}});
                      setShowCreateAppModal(false);
                    }}
                  >
                    {item.name}
                    {!!item.local && `, ${item.local}`}
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </section>
      </CreateAppModal>
    </>
  );
};

export default Tabs;
