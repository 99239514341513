/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../../store/store';
import {useLocation, useParams} from 'react-router-dom';
import PageWrapper from '../../../../modules/PageWrapper';

const AccountsWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Facebook Аккаунты', path: 'traffics/facebook/accounts', isActive: true},
      ]}
      title={'Facebook Аккаунты'}
      firstTimeConfig='today'
    >
      <Accounts />
    </PageWrapper>
  );
};

const Accounts = observer(({isVerify, setPreloader, timeConfig}) => {
  const store = useStore();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    isVerify && getData();
  }, [isVerify]);

  const getData = async () => {
    const [getAccounts] = await Promise.all([store.trafficsFacebookAccountGetAccounts()]);

    setAccounts(getAccounts);
    setPreloader(false);
  };

  const update = async (id) => {
    await store.trafficsFacebookAccountGetAccountStats({timeConfig, id});
  };

  return (
    <div className='card'>
      <div className='card-header border-0 pt-6'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Аккаунты</h3>
        </div>
      </div>
      <div className='card-body py-4'>
        <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-125px text-start' style={{whiteSpace: 'nowrap'}}>
                    Название аккаунта
                  </th>
                  <th className='min-w-125px text-start' style={{whiteSpace: 'nowrap'}}>
                    Байер
                  </th>
                  <th className='min-w-125px text-center' style={{whiteSpace: 'nowrap'}}>
                    Статус аккаунта
                  </th>
                  <th className='w-90px text-start'></th>
                </tr>
              </thead>

              <tbody className='text-gray-600 fw-semibold'>
                {accounts?.map((item, i) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div
                          className='d-flex fs-6 text-gray-800'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <span
                            className='me-2'
                            style={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              maxWidth: '350px',
                            }}
                          >
                            {item?.account_name}
                          </span>
                          <span className='me-2 text-gray-400'>#{item?.account_id}</span>
                        </div>
                      </td>
                      <td>
                        <div
                          className='d-flex fs-6 text-gray-800'
                          style={{display: 'flex', flexDirection: 'column'}}
                        >
                          <span className='me-2' style={{whiteSpace: 'nowrap'}}>
                            {item?.bayer_fname} {item?.bayer_lname}
                          </span>
                          <span className='me-2 text-gray-400'>#{item?.bayer_id}</span>
                        </div>
                      </td>
                      <td className='text-center'>
                        <div
                          className={`badge ${
                            !item.active ? 'badge-light-danger' : 'badge-light-success'
                          }`}
                        >
                          {!item.active ? 'Не активный' : 'Активный'}
                        </div>
                      </td>
                      <td className='text-end'>
                        <span
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={() => update(item?.id)}
                        >
                          <i className='ki-duotone ki-abstract-33 fs-2'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AccountsWrapper;
