/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import Finances from '../../../widgets/Finances';
import Chart from '../../../widgets/Chart';
import PageWrapper from '../../../modules/PageWrapper';
import {formatNumber, formatNumber2, formatNumberToCash2} from '../../../functions/formatNumber';
import Card from '../../../shared/Card';
import {ReactComponent as Coin} from './assets/coin.svg';
import {ReactComponent as Receive} from './assets/card-receive.svg';
import {ReactComponent as Send} from './assets/card-send.svg';
import {ReactComponent as Percent} from './assets/discount-shape.svg';
import {SelectorWithSearch} from '../../../shared/Selectors';
import {useThemeMode} from '../../../../_metronic/partials';

const DashboardPage = observer(({timeConfig, isVerify, setPreloader}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  const [salesStats, setSalesStats] = useState({});
  const [qualificationSchedule, setQualificationSchedule] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [bots, setBots] = useState([]);
  const [offers, setOffers] = useState([]);
  const [channels, setChannels] = useState([]);

  const [advertiser, setAdvertiser] = useState({});
  const [bot, setBot] = useState({});
  const [channel, setChannel] = useState({});
  const [offer, setOffer] = useState({});

  const [params, setParams] = useState({});

  const getFilters = async () => {
    const [getAdvertisers, getBots, getChannels, getOffers] = await Promise.all([
      store.getAdvertisersList(),
      store.botsGetListBots(),
      store.channelsGetChannelsList(''),
      store.offersGetOffersList(),
    ]);

    setAdvertisers(
      getAdvertisers.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setBots(
      getBots.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setChannels(
      getChannels.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setOffers(
      getOffers.map((item) => {
        return {value: item.id, label: `${item.name} #${item.id}`};
      })
    );
    setPreloader(false);
  };

  useEffect(() => {
    let queryStr = '';
    for (let [key, value] of Object.entries(params)) {
      queryStr += `${key}=${value}`;
    }
    isVerify && timeConfig.time_config && getData(queryStr) && getFilters();
  }, [timeConfig, isVerify]);

  const getData = async (filters) => {
    const [getStats, qualifications] = await Promise.all([
      store.getMainStatsInfluence(timeConfig, filters),
      store.getQualificationScheduleInfluence(timeConfig, filters),
    ]);
    setQualificationSchedule(qualifications);
    setSalesStats(getStats);

    setPreloader(false);
  };

  const createParams = async (field, value) => {
    const newParams = {...params, [field]: value};
    setParams(newParams);
    let queryStr = '';
    for (let [key, value] of Object.entries(newParams)) {
      queryStr += `${key}=${value}`;
    }

    getData(queryStr);
  };

  const deleteParams = async (field) => {
    const newParams = {...params};
    delete newParams[field];
    setParams(newParams);
    let queryStr = '';
    for (let [key, value] of Object.entries(newParams)) {
      queryStr += `${key}=${value}`;
    }

    getData(queryStr);
  };

  return (
    <>
      <div className='filtersSalesPanel g-5 g-xl-10 my-5'>
        <label
          htmlFor='filtersSalesPanel'
          className='filtersSalesPanel_activator fw-bold text-gray-800'
          style={{
            backgroundColor: `${mode === 'dark' ? '#15171C' : '#ffffff'}`,
          }}
        >
          Фильтра
          <input className='' type='checkbox' name='' id='filtersSalesPanel' />
          <i className='ki-duotone ki-down fs-1'></i>
          <i className='ki-duotone ki-up fs-1'></i>
        </label>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Рекламодатель...'
            options={advertisers}
            actions={(e) => {
              setAdvertiser(e);
              if (e === null) {
                return deleteParams('&advertiser_id');
              }
              createParams('&advertiser_id', e.value);
            }}
            value={advertiser}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Офферы...'
            options={offers}
            actions={(e) => {
              setOffer(e);
              if (e === null) {
                return deleteParams('&offer_id');
              }
              createParams('&offer_id', e.value);
            }}
            value={offer}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Боты...'
            options={bots}
            actions={(e) => {
              setBot(e);
              if (e === null) {
                return deleteParams('&bot_id');
              }
              createParams('&bot_id', e.value);
            }}
            value={bot}
            clearable={true}
          />
        </div>
        <div className='filtersSalesPanel_item'>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '42px',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                backgroundColor: mode === 'dark' ? '#15171C !important' : '#ffffff !important',
              }),
            }}
            placeholder='Каналы...'
            options={channels}
            actions={(e) => {
              setChannel(e);
              if (e === null) {
                return deleteParams('&channel_id');
              }
              createParams('&channel_id', e.value);
            }}
            value={channel}
            clearable={true}
          />
        </div>
      </div>
      {!store.roles.includes(19) && !store.roles.includes(6) && (
        <div className='row g-4 mb-4'>
          <div className='col-6 col-md-3'>
            <Card className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Coin />
                Доход
              </div>
              <div
                className='text-gray-700'
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                {formatNumberToCash2(salesStats?.revenue)}
              </div>
            </Card>
          </div>
          <div className='col-6 col-md-3'>
            <Card className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Receive />
                Прибыль
              </div>
              <div
                className='text-gray-700'
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                {formatNumberToCash2(salesStats?.profit)}
              </div>
            </Card>
          </div>
          <div className='col-6 col-md-3'>
            <Card className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Send />
                Расходы
              </div>
              <div
                className='text-gray-700'
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginTop: '20px',
                }}
              >
                {formatNumberToCash2(salesStats?.cost)}
              </div>
            </Card>
          </div>
          <div className='col-6 col-md-3'>
            <Card className='card p-4 card-flush'>
              <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
                <Percent />
                ROMI
              </div>
              <div
                className='text-gray-700'
                style={{fontSize: '18px', fontWeight: '600', marginTop: '20px'}}
              >
                {formatNumber(salesStats?.romi)}%
              </div>
            </Card>
          </div>
        </div>
      )}
      <div className='row g-5 g-xl-10 mt-0'>
        <div className='col-xl-4 mb-5 mt-0'>
          <Finances
            background={'inf'}
            title='Статистика'
            subTitle='Управляйте периодом в календаре.'
            stats={[
              {
                title: 'Пользователей',
                number: formatNumber(salesStats?.count_leads) || 0,
                icon: (
                  <i className='ki-duotone ki-fingerprint-scanning fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                ),
              },
              {
                title: 'Регистрации',
                number: formatNumber(salesStats?.count_registrations) || 0,
                icon: (
                  <i className='ki-duotone ki-user-edit fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
              {
                title: 'Обращения',
                number: formatNumber(salesStats?.count_appeals) || 0,
                icon: (
                  <i className='ki-duotone ki-wallet fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                  </i>
                ),
              },
              {
                title: 'Квалификации',
                number: formatNumber(salesStats?.count_qualifications) || 0,
                icon: (
                  <i className='ki-duotone ki-user-tick fs-1 text-danger'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                ),
              },
            ]}
          />
        </div>

        <div className='col-xl-8 mb-5 mt-xl-0'>
          <Chart data={{graph: qualificationSchedule}} />
        </div>
      </div>
      <div className='row g-4 mb-4'>
        <div className='col-12 col-md-4'>
          <Card className='card p-4 card-flush'>
            <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
              <i className='ki-duotone ki-message-add fs-2x text-danger'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
              subscribe2messages
            </div>
            <div
              className='text-gray-700'
              style={{
                fontSize: '18px',
                fontWeight: '600',
                marginTop: '20px',
              }}
            >
              {formatNumber2(salesStats?.subscribe2appeals)}
            </div>
          </Card>
        </div>
        <div className='col-12 col-md-4'>
          <Card className='card p-4 card-flush'>
            <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
              <i className='ki-duotone ki-message-edit fs-2x text-danger'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              messages2reg
            </div>
            <div
              className='text-gray-700'
              style={{
                fontSize: '18px',
                fontWeight: '600',
                marginTop: '20px',
              }}
            >
              {formatNumber2(salesStats?.appeals2reg)}
            </div>
          </Card>
        </div>
        <div className='col-12 col-md-4'>
          <Card className='card p-4 card-flush'>
            <div className='d-flex fw-bold gap-2 align-items-center' style={{fontSize: '20px'}}>
              <i className='ki-duotone ki-row-vertical fs-2x text-danger'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              reg2cpa
            </div>
            <div
              className='text-gray-700'
              style={{fontSize: '18px', fontWeight: '600', marginTop: '20px'}}
            >
              {formatNumber2(salesStats?.reg2cpa)}
            </div>
          </Card>
        </div>
      </div>
    </>
  );
});

const InfluencePanel = () => {
  return (
    <>
      <PageWrapper
        firstTimeConfig='current_month'
        breadcrumbs={[
          {title: 'PanelHunt', path: ''},
          {isSeparator: true},
          {title: 'Панель', path: '/dashboard/influence', isActive: true},
        ]}
        title={'Панель Influence'}
      >
        <DashboardPage />
      </PageWrapper>
    </>
  );
};

export default InfluencePanel;
