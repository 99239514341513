/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import Card from '../../../../shared/Card';
import {formatNumber, formatNumber2} from '../../../../functions/formatNumber';

const Board2 = ({data, isBlogger = false}) => {
  return (
    <Card className='card card-flush mt-6 mt-xl-9'>
      <div className='card-body'>
        <div className='row p-0 mb-5'>
          <div className='col'>
            <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
              <span className='fs-4 fw-semibold text-danger-emphasis d-block'>subscribe2messages</span>
              <span className='fs-2hx fw-bold text-gray-900 counted'>
                {formatNumber2(data?.subscribe2appeals)}
              </span>
            </div>
          </div>
          <div className='col'>
            <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
              <span className='fs-4 fw-semibold text-warning-emphasis d-block'>messages2reg</span>
              <span className='fs-2hx fw-bold text-gray-900 counted'>
                {formatNumber2(data?.appeals2reg)}
              </span>
            </div>
          </div>

          <div className='col'>
            <div className='border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3'>
              <span className='fs-4 fw-semibold text-info-emphasis d-block'>reg2cpa</span>
              <span className='fs-2hx fw-bold text-gray-900 counted'>
                {formatNumber2(data?.reg2cpa)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Board2;
